.experience {
  margin-top: 2rem;
}

.experience * {
  color: var(--text-color);
  line-height: 2;
  text-align: start;
}

.experience h1 {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.experience h1:hover {
  cursor: text;
  opacity: 1;
}

.experience-component {
  width: 70%;
  margin: auto;
  padding: 2rem 0;
}

.experience-component:not(:last-child) {
  border-bottom: 1px solid black;
}

.experience-component h2 {
  font-size: 20px;
  font-weight: bold;
}

.experience-component h3 {
  font-size: 15px;
  font-weight: bold;
}

.experience-component #InfomediaLogo {
  height: 13rem;
}

.experience-component #UNSWLogo {
  height: 5rem;
}

.experience-component #PearlerLogo {
  height: 10rem;
}

.experience-component #MSALogo {
  height: 10rem;
}

.experience-component-title {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 3rem;
}

.experience-component-title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.experience-component ul {
  list-style: disc !important;
}

.experience-component-title img {
  width: 300px;
  height: 200px;
  object-fit: scale-down;
  max-width: 90%;
}

@media screen and (max-width: 1024px) {
  .experience-component {
    width: 90%;
  }

  .experience-component img {
    height: 15rem;
  }
}

@media screen and (max-width: 768px) {
  .experience-component {
    width: 90%;
  }

  .experience-component img {
    height: 10rem;
  }

  .experience-component-title {
    flex-direction: column;
    gap: 0;
  }
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
  padding: 2rem;
  background: var(--background-accent);
  border-radius: 8px;
}

.image-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image-group img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-group p {
  text-align: center;
  font-style: italic;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Existing styles remain */

/* Modified image gallery section */
.image-gallery {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 3rem;
  padding: 3rem;
  justify-content: center;
}

.image-group {
  margin: 1.5rem 0;
}

.image-group img {
  max-width: 600px;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-group img:hover {
  transform: scale(1.02);
}

.image-gallery-center {
  /* align-items: center;
  justify-content: center;
  display: grid;
  margin: 1.5rem 0; */
}

.image-group-center {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
}

.image-group-center img {
  max-width: 600px;
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.image-group-center p {
  text-align: center;
  font-style: italic;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.home-profile {
  /* background-color: #13498e; */
  /* background-color: #33373c; */
  /* background-color: var(--background-color); */
  display: flex;
  flex-direction: column;
  height: 90vh;
  justify-content: space-around;
  align-items: center;
  /* padding: 20% 3rem 3rem 3rem; */
  /* gap: 3rem; */
}

.home-profile-top {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  width: 80%;
  align-items: center;
  justify-content: space-evenly;
}

.home-profile h1,
.home-profile h2 {
  font-size: 30px;
  margin-bottom: 2rem;
}

.home-profile h1:hover {
  cursor: auto;
  opacity: 1;
}

.home-profile img {
  height: 10rem;
}

.home-profile-content p {
  font-family: "Shantell Sans";
}

.home-profile-logo {
  position: absolute;
  bottom: 2rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* position: relative; */
  /* bottom: 2rem; */
}

.home-profile-logo a:hover {
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  .home-profile-top {
    width: 100%;
  }

  .home-profile {
    /* height: 50vh; */

    padding: 2rem;
  }

  .home-profile img {
    height: 8rem;
  }

  .home-profile h1 {
    font-size: 25px;
  }

  .home-profile h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 768px) {
  .home-profile {
    /* height: 50%; */
    justify-content: safe center;
    flex-direction: column;
    /* padding: 20% 1rem 1rem 1rem; */
  }

  .home-profile img {
    margin: auto;
  }

  .home-profile h1 {
    font-size: 20px;
  }

  .home-profile h2 {
    font-size: 15px;
  }

  .home-profile-top {
    flex-direction: column;
  }

  .home-profile-content p {
    font-size: 12px;
  }
}

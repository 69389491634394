/* FILEPATH: /Users/vincent/Desktop/personal-website/src/components/Footer/footer.css */

/* Add your CSS styles for the footer here */
footer {
  position: relative;
  bottom: 0;
  /* background-color: black !important; */
  padding: 20px;
  text-align: center;
}

footer p {
  color: darkgrey;
  font-size: 14px;
  padding: 1rem;
}

footer a {
  color: #555;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 980px) {
  body.is-navPanel-visible #wrapper {
    opacity: 0.5;
  }

  body.is-navPanel-visible #navPanel {
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.2);
    visibility: visible;
  }
}

:root {
  /* Light theme colors */
  --background-color-light: white;
  --text-color-light: black;
  --header-bg-light: white;

  /* Dark theme colors */
  --background-color-dark: #33373c;
  --text-color-dark: #bdbdbd;
  --header-bg-dark: #33373c;

  /* --menu-icon-filter: invert(0%); */
}

body[data-theme="light"] {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --header-bg: var(--header-bg-light);
}

body[data-theme="dark"] {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --header-bg: var(--header-bg-dark);
  /* --menu-icon-filter: invert(100%); */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home-profile {
  background-color: var(--background-color);
  /* color: var(--text-color); */
  /* transition: background-color 0.3s ease, color 0.3s ease; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease;
}

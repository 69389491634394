.contact {
  margin-top: 3rem;
}

.contact * {
  color: var(--text-color);
  line-height: normal;
  padding: 0.5rem;
}

.contact h1 {
  font-size: 20px;
}

.contact h1:hover {
  cursor: text;
  opacity: 1;
}

.contact a {
  color: lightskyblue;
}

.contact a:hover {
  opacity: 0.8;
}

.contact-component Footer {
  position: absolute;
  margin-bottom: 1rem;
  width: 100%;
}

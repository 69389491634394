.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    text-align: center;
    padding: 2rem;
  }
  
  .not-found h1 {
    font-size: 6rem;
    color: var(--text-color);
    margin-bottom: 1rem;
  }

  .not-found h1:hover {
    cursor: default;
    opacity: 1;
  }
  
  .not-found h2 {
    font-size: 2rem;
    color: var(--text-color);
    margin-bottom: 1rem;
  }
  
  .not-found p {
    color: var(--text-color);
    margin-bottom: 2rem;
  }
  
  .not-found .home-link {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #424958;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .not-found .home-link:hover {
    background-color: #4f5767;
    text-decoration: none;
  }
  
  @media screen and (max-width: 768px) {
    .not-found h1 {
      font-size: 4rem;
    }
  
    .not-found h2 {
      font-size: 1.5rem;
    }
  }

  .not-found-container footer {
    position: absolute;
    bottom: 0;
    margin-top: 100px;
    width: 100%;
    text-align: center;
  }
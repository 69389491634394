.education {
  /* background-color: white; */
  background-color: var(--background-color);
  margin: auto;
  display: flex;
  padding: 2rem;
  width: 80%;
}

.education * {
  color: var(--text-color);
  line-height: normal;
  padding: 0.5rem;
}

.education-history {
  margin: auto;
  margin-top: 1rem;
  text-align: start;
}

.education-history h1,
.education-history h2,
.education-history h3 {
  font-weight: bold;
}

.education-history h1 {
  font-size: 20px;
  /* padding: 1rem; */
}

.education-history h1:hover {
  cursor: text;
  opacity: 1;
}

.education-history h2 {
  font-size: 15px;
}

.education-history img {
  width: 10rem;
}

.relevant-subject {
  display: inline-block;
  /* flex-direction: column; */
}

.relevant-subject-left,
.relevant-subject-right {
  display: inline-block;
}

.relevant-subject ul {
  list-style-type: disc;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .education {
    flex-direction: column;
    padding: 1rem;
    width: 95%;
  }

  .education-history {
    margin: auto;
    margin-top: 1rem;
    text-align: start;
  }

  .education-history h1,
  .education-history h2,
  .education-history h3 {
    font-weight: bold;
  }

  .education-history h1 {
    font-size: 20px;
    /* padding: 1rem; */
  }

  .education-history h2 {
    font-size: 15px;
  }

  .education-history img {
    width: 10rem;
  }

  .relevant-subject li {
    margin-bottom: 0 !important;
  }
}

.header {
  /* background-color: #33373c; */
  background-color: var(--header-bg);
  display: flex;
  flex-direction: row;
  padding: 1rem 6rem;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 4rem;
  height: 10vh;
}

.navigation {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  font-size: 20px;
}

.menu-icon img {
  display: none;
  height: 2rem;
}

.menu-close-icon-mobile,
.navigation-mobile {
  display: none;
}

.header h1 {
  font-size: 25px;
  color: rgb(171, 203, 236);
}

h1:hover,
.navigation a:hover {
  opacity: 0.5;
  cursor: pointer;
}

@media (max-width: 980px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .header .navigation {
    flex-direction: column;
    gap: 1rem;
  }

  .header h1 {
    font-size: 20px;
  }

  .header .navigation a {
    font-size: 15px;
  }

  .menu-icon img {
    display: block;
  }

  .menu-icon img:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .menu-close-icon-mobile {
    display: inline-block;
    text-align: center;
    /* width: fit-content; */
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .menu-close-icon-mobile img {
    height: 2rem;
    color: white;
  }

  .menu-close-icon-mobile:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .navigation {
    display: none !important;
  }

  /* .navigation-mobile {
    display: none;
  } */

  .navigation-mobile * {
    /* color: black; */
  }

  .navigation-mobile.open {
    /* right: 0; */
    display: block;
    padding: 1rem 2rem;
    background-color: rgb(70, 82, 94);
    position: fixed;
    height: fit-content;
    width: fit-content;
    transition: right 0.5s ease;
    text-align: start;
    right: 2rem;
    top: 8vh;
    /* top: 1rem; */
    border-radius: 10px;
    z-index: 1000;
  }

  .navigation-mobile a {
    color: #bdbdbd;
    font-size: 15px;
    cursor: pointer;
  }

  .navigation-mobile a:hover {
    opacity: 0.5;
  }

  ul li:not(:last-child) {
    margin: 0 0 1rem 0;
  }
}

/* Add these to your existing CSS */

/* Desktop Dropdown Styles */
.dropdown-container {
  position: relative;
  cursor: pointer;
}

.dropdown-header {
  padding: 10px 15px;
  color: inherit;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--background-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  min-width: 200px;
  z-index: 100;
}

.dropdown-menu.visible {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu a {
  display: block;
  padding: 10px 20px;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
}

.dropdown-menu a:hover {
  background-color: var(--hover-color);
}

/* Mobile Dropdown Styles */
.navigation-mobile .mobile-dropdown {
  position: relative;
}

.navigation-mobile .submenu {
  max-height: 0;
  /* overflow: hidden; */
  transition: max-height 0.3s ease-out;
  padding-left: 20px;
}

.navigation-mobile .submenu.open {
  max-height: 200px;
}

.navigation-mobile .mobile-dropdown > span {
  display: block;
  padding: 12px 0;
}

/* Adjust existing mobile menu if needed */
.navigation-mobile ul {
  z-index: 1000; /* Ensure dropdowns appear above other content */
}

.navigation-mobile * {
  color: #bdbdbd !important;
}

.menu-icon img {
  filter: var(--menu-icon-filter);
}

.gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* padding-top: 11rem; */

  margin: 2rem 2rem;
  margin-bottom: 2rem;
}

.gallery * {
  color: var(--text-color);
  line-height: normal;
}

.gallery img {
  object-fit: cover;
  width: 400px;
  height: 400px;
  padding: 1rem;
}

.gallery h1 {
  font-size: 20px;
  margin-bottom: 1rem;
}

.gallery h3 {
  color: var(--text-color);
}

.gallery-main-content {
  width: 100%;
  margin-top: 2rem !important;
}

.gallery-individual-image {
  display: flex;
  justify-content: center;
}

.individual-image-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
}

.individual-image-component h3 {
  color: var(--text-color);
}

.individual-image-component p {
  color: var(--text-color);
}

.individual-image-component img {
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.individual-image-component img[src^="data:image"] {
  opacity: 0;
}

.individual-image-component img[src^="http"], 
.individual-image-component img[src^="/"] {
  opacity: 1;
}

/* Optional loading skeleton */
.individual-image-component::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #eee; */
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.individual-image-component img[src^="http"] + .individual-image-component::before,
.individual-image-component img[src^="/"] + .individual-image-component::before {
  opacity: 0;
}

#operaharbour img {
  width: 800px;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

#botany img {
  width: 500px;
  max-width: 100vw;
  height: auto;
}

@media (max-width: 1385px) {
  .gallery img {
    width: 100%;
    height: 400px;
  }
}

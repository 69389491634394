.home-about-me {
  margin: 3.5rem auto;
  width: 80%;
}

.home-about-me * {
  /* color: black; */
  color: var(--text-color);
}

.home-about-me-profile-text {
  display: flex;
  flex-direction: row;
}

.home-about-me h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: normal;
}

.home-about-me h1:hover {
  cursor: text;
  opacity: 1;
}

.home-about-me h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: normal;
}

.home-about-me p {
  line-height: normal;
  text-align: justify;
}

.home-about-me img {
  width: 30%;
  max-width: 12rem;
  float: left;
  margin: 0 2rem 1rem 0;
  /* display: inline-block; */
}

@media screen and (max-width: 1024px) {
  .home-about-me {
    width: 90%;
  }

  .home-about-me img {
    width: 30%;
    max-width: 12rem;
    float: left;
    margin: 0 1rem 1rem 0;
    /* display: inline-block; */
  }
}

.tech-line {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin: 8px 0;
}

.tech-icon {
  font-size: 1.5rem;
  color: var(--text-color);
  transition: transform 0.2s;
}

.tech-icon:hover {
  transform: scale(1.2);
}

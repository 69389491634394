.contact-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group input,
.form-group textarea {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button {
  margin-top: 2rem;
  padding: 0.75rem;
  background-color: #282c34;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contact-form button:hover:not(:disabled) {
  background-color: #4f5767;
  cursor: pointer;
}

.contact-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.contact-form button.submitting {
  opacity: 0.7;
}

.form-success {
  text-align: center;
  padding: 2rem;
  color: #28a745;
}

@media screen and (max-width: 1024px) {
  .contact-form {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
    width: 95%;
  }
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  transition: opacity 0.3s ease;
}

.theme-toggle:hover {
  opacity: 0.7;
}

.theme-toggle svg {
  width: 24px;
  height: 24px;
}

.theme-toggle path {
  color: var(--text-color);
}

@media (max-width: 980px) {
  .theme-toggle {
    margin: 0 auto;
  }
}
